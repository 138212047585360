<template>
  <div>
    <template v-if="!editable">
      <a-row :gutter="32">
        <a-col :sm="24" :lg="14">
          <div class="card-full">
            <div class="detail">
              <div class="cui__utils__heading mb-0">
                <strong>QUESTIONS PREVIEW</strong>
              </div>
              <div class="text-muted mb-3">You can see Questions here</div>
              <div class="d-lg-flex flex-row flex-wrap">
                <a-form-item class="mr-lg-3">
                  <a-button
                    v-show="excelData.length && !editable"
                    @click.prevent="backUpload"
                    type="default"
                    block
                  >
                    <a-icon type="left" />Back to Upload Kartu Soal
                  </a-button>
                </a-form-item>
                <a-form-item>
                  <a-button
                    @click.prevent="cekEmpty"
                    v-show="excelData.length"
                    :loading="loadingEditQuestion"
                    type="primary"
                    block
                  >
                    <a-icon v-if="!loadingEditQuestion" type="edit" />Edit
                    Questions Card
                  </a-button>
                </a-form-item>
              </div>
              <hr />
              <template v-if="!excelData.length">
                <a-result
                  status="404"
                  title="It's Empty"
                  sub-title="You have to upload the Kartu Soal"
                >
                  <template #extra>
                    <a-upload
                      :before-upload="excelExport"
                      :showUploadList="false"
                    >
                      <a-button
                        :loading="loadingUpload"
                        type="default"
                        size="large"
                      >
                        <a-icon type="upload" />Upload Kartu Soal
                      </a-button>
                    </a-upload>
                    <a-button
                      @click.prevent="toCreateSoal"
                      type="primary"
                      size="large"
                      class="mt-3"
                    >
                      <a-icon type="plus" />Create Kartu Soal
                    </a-button>
                    <br />
                    <br />
                    <a href="/Template.xlsx" download>Download Template</a>
                  </template>
                </a-result>
                <!-- <EmptyKartuSoal /> -->
                <KartuSoalGuide />
              </template>
              <template v-if="excelData.length && !editable">
                <CounterCbt :counterCBT="counterCBT" />
                <ListSoalCbt :listSoal="excelData" />
              </template>
            </div>
          </div>
        </a-col>
        <a-col :sm="24" :lg="10">
          <!-- <Card>
            <CbtForm
              :dataCBT="dataCBT"
              :selectedPengawas="selectedPengawas"
              :listMapels="mapels"
              :listLevels="levels"
              :listTypes="types"
              :listPengawas="pengawas"
              :isFilledSoal="isFillSoal"
              @save-cbt="generateCBT"
            />
          </Card>-->
          <div class="card-full">
            <div class="detail">
              <div class="cui__utils__heading mb-0">
                <strong>GENERATE CBT</strong>
              </div>
              <div class="text-muted mb-3">You can generate CBT here</div>
              <hr />
              <a-row :gutter="16">
                <a-col :span="24">
                  <a-form-item label="Mata Pelajaran">
                    <a-select
                      size="large"
                      style="width: 100%; height: 40px"
                      @change="handleSelectMapel"
                    >
                      <a-select-option
                        v-for="item in mapels"
                        :key="item.id_mata_pelajaran"
                        :value="item.id_mata_pelajaran"
                        >{{ item.mata_pelajaran.nama }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Level">
                    <a-select
                      :disabled="!dataCBT.id_mata_pelajaran"
                      size="large"
                      style="width: 100%; height: 40px"
                      @change="handleSelectClass"
                    >
                      <a-select-option
                        v-for="item in filterLevels"
                        :key="item.id"
                        :value="item.kela.tingkat"
                        >{{ item.kela.tingkat }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Type">
                    <a-select
                      size="large"
                      style="width: 100%; height: 40px"
                      @change="handleSelectType"
                    >
                      <a-select-option value="PH">PH</a-select-option>
                      <a-select-option value="PTS">PTS</a-select-option>
                      <a-select-option value="PAS">PAS</a-select-option>
                      <a-select-option value="PAT">PAT</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="dataCBT.tipe !== 'PH'">
                  <a-form-item label="Pengawas 1">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 100%; height: 40px"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 1)"
                      size="large"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.nama }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="dataCBT.tipe !== 'PH'">
                  <a-form-item label="Pengawas 2">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 100%; height: 40px"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 2)"
                      size="large"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.nama }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="dataCBT.tipe !== 'PH'">
                  <a-form-item label="Pengawas 3">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 100%; height: 40px"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 3)"
                      size="large"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.nama }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12" v-if="dataCBT.tipe !== 'PH'">
                  <a-form-item label="Pengawas 4">
                    <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 100%; height: 40px"
                      :filter-option="filterOption"
                      @change="handleSelectPengawas($event, 4)"
                      size="large"
                    >
                      <a-select-option
                        v-for="item in pengawas"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.nama }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="Duration (minutes)">
                    <a-input-number
                      :min="15"
                      :max="180"
                      step="15"
                      size="large"
                      @change="handleDuration"
                      style="width: 100%"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="Start at">
                    <a-date-picker
                      v-model="dataCBT.waktu_ujian_dibuka"
                      :disabled-date="disabledStartDate"
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                      @openChange="handleStartOpenChange"
                      style="width: 100%"
                      size="large"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="End at">
                    <a-date-picker
                      v-model="dataCBT.waktu_ujian_ditutup"
                      :disabled-date="disabledEndDate"
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                      :open="endOpen"
                      @openChange="handleEndOpenChange"
                      style="width: 100%"
                      size="large"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="Intructions">
                    <ckeditor
                      v-model="dataCBT.instruksi"
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>
                  </a-form-item>
                </a-col>
                <a-button
                  :loading="loadingAdd"
                  type="primary"
                  size="large"
                  block
                  @click.prevent="generateCBT"
                  :disabled="isFillAll"
                  >Generate Now</a-button
                >
              </a-row>
            </div>
          </div>
        </a-col>
      </a-row>
    </template>
    <template v-else>
      <EditSoalCbt
        :listSoal="excelNewData"
        :isAttendanceSubmitted="false"
        @save-edit="saveEdit"
        @cancel-edit="cancelEdit"
        :useCard="true"
        :isCbt="true"
      />
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import XLSX from 'xlsx'
import VueSticky from 'vue-sticky'
// const EmptyKartuSoal = () => import('@/components/app/CbtGuru/EmptyKartuSoal')
// const Card = () => import('@/components/app/Card')
const KartuSoalGuide = () => import('@/components/app/CbtGuru/KartuSoalGuide')
const CounterCbt = () => import('@/components/app/CbtGuru/CounterCbt')
const ListSoalCbt = () => import('@/components/app/CbtGuru/ListSoalCbt')
const EditSoalCbt = () => import('@/components/app/CbtGuru/EditSoalCbt')
// const CbtForm = () => import('@/components/app/CbtGuru/CbtForm')
const types = ['PH', 'PTS', 'PAS', 'PAT']
const intruksi = `
  <p><strong>PETUNJUK UMUM&nbsp;</strong></p><p>1. Dahulukan menjawab soal-soal yang lebih mudah.&nbsp;</p><p>2. Periksa dan bacalah soal-soal dengan teliti sebelum menjawabnya.&nbsp;</p><p>3. Laporkan kepada pengawas ruang ujian, apabila terdapat soal/jawaban rusak, tidak lengkap dan tidak jelas.&nbsp;</p><p>4. Waktu yang disediakan untuk mengerjakan semua jawaban sesuai dengan jadwal yang ditentukan, apabila terlambat tidak diberikan penambahan waktu.&nbsp;</p><p>5. Tidak diizinkan menggunakan kalkulatorm HP, Tabel matematika atau alat bantu lainnya.&nbsp;</p><p>6. Periksa kembali pekerjaannya, sebelum disubmit kepada sistem.&nbsp;</p><p>7. Selama ujian berlangsung, peserta ujian harus mematuhi tata-tertib yang berlaku, bagi yang melanggarnya akan dikenakan sanksi.&nbsp;</p><p>8.<i><strong> <u>Mulailah mengerjakan soal dengan membaca lafadz basmalah dan shalawat, dan akhiri dengan hamdalah</u></strong></i></p>
`
export default {
  components: {
    // EmptyKartuSoal,
    // Card,
    KartuSoalGuide,
    ListSoalCbt,
    CounterCbt,
    EditSoalCbt,
    // CbtForm,
  },
  data() {
    return {
      top: 10,
      loadingUpload: false,
      editable: false,
      loadingEditQuestion: false,
      newComponent: false,
      levels: [],
      filterLevels: [],
      mapels: [],
      types,
      pengawas: [],
      selectedPengawas: {
        pengawas1: '',
        pengawas2: '',
        pengawas3: '',
        pengawas4: '',
      },
      newData: {
        id_kelas: '',
      },
      excelData: [],
      excelNewData: [],
      dataCBT: {
        id_mata_pelajaran: '',
        durasi: 15,
        tingkat: '',
        tipe: this.tipeCbt,
        kkm: 0,
        waktu_ujian_dibuka: null,
        waktu_ujian_ditutup: null,
        instruksi: intruksi,
        tipe_cbt: '',
      },
      counterCBT: {
        counterQuestions: 0,
        counterEssay: 0,
        counterMultipleChoice: 0,
        counterEmpty: 0,
        counterBobot: 0,
      },
      endOpen: false,
      loadingAdd: false,
    }
  },
  methods: {
    saveCBT(payload) {
      payload.dataCBT.tipe = this.tipeCbt
      // console.log('payload', payload)
    },
    toCreateSoal() {
      this.editable = true
    },
    cancelEdit() {
      this.editable = false
    },
    saveEdit(payload) {
      this.editable = false
      this.excelData = payload
      this.$notification.success({
        message: 'Success.',
        description: 'Your Kartu Soal has been updated',
      })
      this.resetCounter()
      this.startCounter()
    },
    resetCounter() {
      this.counterCBT.counterQuestions = 0
      this.counterCBT.counterEssay = 0
      this.counterCBT.counterMultipleChoice = 0
      this.counterCBT.counterBobot = 0
      this.counterCBT.counterEmpty = 0
    },
    startCounter() {
      this.counterCBT.counterQuestions = this.excelData.length
      this.excelData.forEach(row => {
        this.counterCBT.counterBobot += parseFloat(row.bobot_soal)
        if (row.tipe_soal === 'Multiple Choice') {
          this.counterCBT.counterMultipleChoice++
        } else if (row.tipe_soal === 'Essay') {
          this.counterCBT.counterEssay++
        } else {
          this.counterCBT.counterEmpty++
        }
      })
    },
    numberQuestion(index) {
      return ++index
    },
    fetchDataMapel() {
      this.$store.dispatch('cbt/FETCH_MAPEL_BY_ID_TEACHER', { idGuru: this.user.id })
        .then(data => {
          // console.log(data)
          this.mapels = data.mapel
          this.levels = data.tingkat
        })
    },
    fetchDataPengawas() {
      this.$store.dispatch('cbt/FETCH_PENGAWAS')
        .then(data => {
          this.pengawas = data
        })
    },
    handleSelectMapel(value) {
      this.dataCBT.id_mata_pelajaran = value
      // console.log(this.dataCBT.id_mata_pelajaran)
      const target = this.levels.filter(el => el.id_mata_pelajaran === this.dataCBT.id_mata_pelajaran)
      // console.log(target)
      if (target) {
        this.filterLevels = target
      }
    },
    handleDuration(value) {
      this.dataCBT.durasi = value
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSelectPengawas(value, no) {
      this.selectedPengawas[`pengawas${no}`] = value
    },
    handleSelectClass(value) {
      this.dataCBT.tingkat = value
    },
    handleSelectType(value) {
      this.dataCBT.tipe = value
    },
    disabledStartDate(startValue) {
      const endValue = this.dataCBT.waktu_ujian_ditutup
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.dataCBT.waktu_ujian_dibuka
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    excelExport(event) {
      this.loadingUpload = true
      if (event.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const reader = new FileReader()
        reader.onload = () => {
          const fileData = reader.result
          const wb = XLSX.read(fileData, { type: 'binary' })
          if (wb.Sheets.Penyusunan) {
            const sheets = XLSX.utils.sheet_to_json(wb.Sheets.Penyusunan)
            const durasi = parseInt(sheets[4].__EMPTY_7.replace(': ', ''))
            const totalSoal = parseInt(sheets[5].__EMPTY_7)
            const kkm = parseInt(sheets[5].__EMPTY)
            const startSoalIndex = 8
            let counterQuestions = 0
            let counterEssay = 0
            let counterMultipleChoice = 0
            let counterEmpty = 0
            let counterBobot = 0
            for (let i = startSoalIndex; i < startSoalIndex + totalSoal; i++) {
              counterQuestions++
              const noSoal = sheets[i].__EMPTY_4
              const sk = sheets[i].__EMPTY ? sheets[i].__EMPTY : ''
              const indikator = sheets[i].__EMPTY_1 ? sheets[i].__EMPTY_1 : ''
              const soal = sheets[i].__EMPTY_3 ? sheets[i].__EMPTY_3 : ''
              let jawaban = sheets[i].__EMPTY_5 ? sheets[i].__EMPTY_5 : ''
              const kunciJawaban = sheets[i].__EMPTY_6 ? sheets[i].__EMPTY_6.toLowerCase() : ''
              const bobotSoal = sheets[i].__EMPTY_7 ? sheets[i].__EMPTY_7 : ''
              const kriteria = sheets[i].__EMPTY_8 ? sheets[i].__EMPTY_8 : ''
              const aspek = ''
              let tipeSoal
              counterBobot += bobotSoal
              if (soal && jawaban === '') {
                tipeSoal = 'Essay'
                counterEssay++
              } else if (soal && jawaban && kunciJawaban) {
                tipeSoal = 'Multiple Choice'
                counterMultipleChoice++
                const newLine = jawaban.replace(/\n/g, 'BARIS_BARU ')
                jawaban = newLine.split('BARIS_BARU ')
              } else {
                tipeSoal = 'Empty'
                counterEmpty++
              }
              const soalObj = {
                no_soal: noSoal,
                tipe_soal: tipeSoal,
                standar_kompetensi: sk,
                indikator,
                soal,
                jawaban,
                kunci_jawaban: kunciJawaban,
                bobot_soal: bobotSoal,
                kriteria,
                aspek,
                kkm,
              }
              this.excelData.push(soalObj)
              this.dataCBT.kkm = kkm
              this.dataCBT.durasi = durasi
            }
            // console.log(this.excelData)
            const counter = { counterQuestions, counterEmpty, counterEssay, counterMultipleChoice, counterBobot }
            this.counterCBT = counter
          } else {
            this.$notification.error({
              message: 'Sorry.',
              description: 'This document is not a Kartu Soal',
            })
          }
        }
        reader.readAsBinaryString(event)
      } else {
        this.$notification.error({
          message: 'Sorry.',
          description: 'XLSX are allowed',
        })
      }
      this.loadingUpload = false
      return false
    },
    cekEmpty() {
      if (!this.editable && this.excelData.length) {
        if (this.counterCBT.counterEmpty > 0) {
          this.editable = false
          this.$notification.error({
            message: 'Sorry.',
            description: `There are ${this.counterCBT.counterEmpty} questions that are still empty, Please recheck the Kartu Soal document`,
          })
        } else if (this.counterCBT.counterBobot !== 100) {
          this.editable = false
          this.$notification.error({
            message: 'Sorry.',
            description: `Total Bobot Soal is ${this.counterCBT.counterBobot}, Make sure total Bobot Soal is 100.`,
          })
        } else {
          this.loadingEditQuestion = true
          const delay = t => new Promise(resolve => setTimeout(resolve, t))
          delay(100).then(() => {
            this.editable = true
            // this.newComponent = true
            this.loadingEditQuestion = false
            this.excelNewData = this.excelData
          })
        }
      } else if (this.editable && this.excelData.length) {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure you want to cancel editing ? your changes will be lost</div>
          ),
          onOk: () => {
            this.editable = false
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Yes',
        })
      }
    },
    backUpload() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to go back to upload Kartu Soal ? your changes will be lost</div>
        ),
        onOk: () => {
          this.excelData = []
          this.counterCBT = {}
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Back',
      })
    },
    updateQuestion() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to update the questions ?</div>
        ),
        onOk: () => {
          this.excelData = []
          this.excelData = this.excelNewData
          this.editable = false
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Update',
      })
    },
    generateCBT() {
      // this.dataCBT = payload.dataCBT
      // this.selectedPengawas = payload.selectedPengawas
      if (this.counterCBT.counterEmpty > 0) {
        this.$notification.error({
          message: 'Sorry.',
          description: `There are ${this.counterCBT.counterEmpty} questions that are still empty, Please recheck the Kartu Soal document`,
        })
      } else if (this.counterCBT.counterBobot !== 100) {
        this.$notification.error({
          message: 'Sorry.',
          description: `Total Bobot Soal is ${this.counterCBT.counterBobot}, Make sure total Bobot Soal is 100.`,
        })
      } else if (this.editable) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'You still editing the Kartu Soal, Make sure you have saved any changes.',
        })
      } else if (this.findDuplicatesPengawas) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'There are duplicated pengawas, Make sure you have a diference pengawas.',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure want to generate CBT ?</div>
          ),
          onOk: () => {
            this.loadingAdd = true
            this.dataCBT.tipe_cbt = this.tipeCbt
            this.dataCBT.waktu_ujian_dibuka = moment(this.dataCBT.waktu_ujian_dibuka).format('YYYY-MM-DD HH:mm:ss')
            this.dataCBT.waktu_ujian_ditutup = moment(this.dataCBT.waktu_ujian_ditutup).format('YYYY-MM-DD HH:mm:ss')
            if (this.dataCBT.tipe === 'PH') {
              this.dataCBT.id_pengawas = null
            }
            const dataCBT = this.dataCBT
            // console.log(dataCBT)
            const pengawas = Object.values(this.selectedPengawas)
            const cbtSoalBundle = this.excelData.map(row => {
              return {
                nomor_soal: row.no_soal,
                soal: row.soal,
                jawaban: row.tipe_soal === 'Essay' ? null : JSON.stringify(row.jawaban),
                kriteria: row.kriteria,
                aspek: row.aspek,
                tipe_soal: row.tipe_soal,
                kunci_jawaban: row.kunci_jawaban,
                bobot_soal: row.bobot_soal,
                indikator: row.indikator,
                standar_kompetensi: row.standar_kompetensi,
              }
            })
            this.$store.dispatch('cbt/POST_CBT', {
              idGuru: this.user.id,
              dataCBT,
              cbtSoalBundle,
              pengawas,
            })
              .then(res => {
                this.loadingAdd = false
                if (res === 'SUCCESS_GENERATE_CBT') {
                  this.dataCBT = {}
                  this.excelData = []
                  this.$notification.success({
                    message: 'Success.',
                    description: 'CBT has been generated',
                  })
                  this.$router.push({ name: 'Cbt Teacher' })
                  this.$store.commit('menu/SET_STATE', {
                    currentMenu: ['Cbt Teacher'],
                  })
                } else if (res === 'SUCCESS_GENERATE_CBT, cbt_soal, cbt_attendance') {
                  this.dataCBT = {}
                  this.excelData = []
                  this.$notification.success({
                    message: 'Success.',
                    description: 'CBT has been generated',
                  })
                  this.$router.push({ name: 'Cbt Teacher' })
                  this.$store.commit('menu/SET_STATE', {
                    currentMenu: ['Cbt Teacher'],
                  })
                } else {
                  this.$notification.error({
                    message: 'Error.',
                    description: 'CBT has not been generated',
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Generate',
        })
      }
    },
  },
  computed: {
    user() { return this.$store.state.user.user },
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      return this.$store.state.ckEditor.editorConfig
    },
    tipeCbt() {
      const multipleChoice = this.counterCBT.counterMultipleChoice
      const essay = this.counterCBT.counterEssay
      let tipeCbt = ''
      if (multipleChoice > 0 && essay > 0) {
        tipeCbt = 'Multiple Choice & Essay'
      } else if (multipleChoice > 0 && essay < 1) {
        tipeCbt = 'Multiple Choice'
      } else if (multipleChoice < 1 && essay > 0) {
        tipeCbt = 'Essay'
      } else {
        tipeCbt = 'Unknown'
      }
      return tipeCbt
    },
    findDuplicatesPengawas() {
      if (this.dataCBT.tipe !== 'PH') {
        const arr = Object.values(this.selectedPengawas)
        const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
        const duplicates = findDuplicates(arr)
        // console.log(duplicates.length)
        if (duplicates.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isFillPengawas() {
      if (this.selectedPengawas.pengawas1 !== '' && this.selectedPengawas.pengawas2 !== '' && this.selectedPengawas.pengawas3 !== '' && this.selectedPengawas.pengawas4 !== '') {
        return true
      } else {
        return false
      }
    },
    isFillSoal() {
      if (this.excelData.length) {
        return true
      } else {
        return false
      }
    },
    isFillCBT() {
      if (this.dataCBT.tipe) {
        if (this.dataCBT.tipe !== 'PH') {
          if (this.dataCBT.id_mata_pelajaran && this.dataCBT.tingkat && this.dataCBT.tipe && this.dataCBT.waktu_ujian_dibuka && this.dataCBT.waktu_ujian_ditutup && this.dataCBT.instruksi && this.isFillPengawas) {
            return true
          } else {
            return false
          }
        } else {
          if (this.dataCBT.id_mata_pelajaran && this.dataCBT.tingkat && this.dataCBT.tipe && this.dataCBT.waktu_ujian_dibuka && this.dataCBT.waktu_ujian_ditutup && this.dataCBT.instruksi) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },
    isFillAll() {
      if (this.excelData.length && this.isFillCBT) {
        return false
      } else {
        return true
      }
    },
  },
  created() {
    this.fetchDataMapel()
    this.fetchDataPengawas()
  },
  directives: {
    sticky: VueSticky,
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 15px;
}
</style>
